<script setup>
  import { computed } from 'vue';
  import { useShared } from '@plenny/visitor';
  import { useScrollWidth } from '$storefront';

  const props = defineProps({
    sticky: Boolean,
  });

  const shared = useShared();
  const scroll = useScrollWidth();

  const scrollWidth = computed(() => scroll.value + 'px');
</script>
<template>
  <div class="cat" :class="{ 'sticky': sticky }">
    <div class="cat-wrapper">
      <ul>
        <HeaderNavItem v-for="(item, index) in shared.categories" :item="item" :depth="0" :index="String(index)" category @close="$emit('close')" />
      </ul>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .cat {
    --space: v-bind(scrollWidth);

    position: absolute;
    top: 100%;
    left: 0;
    flex-direction: column;
    display: flex;
    width: 100%;
    max-width: 1600px;
    z-index: 99;

    &.sticky {
      top: 90px;
      left: calc(50% + 150px);
      max-width: 1300px;
      max-height: calc(100svh - 100px);

      @media(max-width: 1650px) {
        left: calc(50% + 30px);
        max-width: 1100px;
      }
      @media(max-width: 1200px) {
        left: calc(50% + 30px);
        max-width: 850px;
      }

      @media(max-width: 992px) {
        top: 70px;
        max-width: 700px;
      }
    }

    .cat-wrapper {
      position: relative;

      > ul {
        overflow-y: auto;
        overscroll-behavior: contain;
        max-height: calc(100svh - 150px);
        list-style: none;
        width: 300px;
        padding: 10px 0 0;
        background-color: var(--color-white);
        box-shadow: var(--box-shadow-menu);
        border-bottom-left-radius: 5px;
        border-right: 1px solid var(--color-medium-gray);
        border-bottom: 1px solid var(--color-medium-gray);
        border-left: 1px solid var(--color-medium-gray);

        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
</style>
