<template>
  <div class="account-details">
    <SfButton :href="route('web.account.session.create')" class="btn-details" primary >
      {{ $t('Zaloguj się') }}
    </SfButton>
    <Separator>
      {{ $t('lub') }}
    </Separator>
    <SfButton :href="route('web.account.register.create')" class="btn-details" >
      {{ $t('Zarejestruj się') }}
    </SfButton>
  </div>
</template>
<style scoped lang="scss">
  .account-details {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
    right: 0;
    top: 100%;
    width: 300px;
    height: auto;
    padding: 2.5rem 1.25rem;
    border-radius: 0.625rem;
    border: var(--color-medium-gray);
    box-shadow: var(--box-shadow-primary);
    background-color: var(--color-white);
    z-index: 9;

    .btn-details {
      width: 100%;
    }
  }
</style>






