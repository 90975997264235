<script setup>
  import { ref } from 'vue';

  defineProps({
    dark: { type: Boolean, default: false },
  });

  const open = ref(false);
</script>

<template>
  <div :class="{'collapse--dark': dark}" class="collapse">
    <div class="collapse__title" @click="open = !open">
      <slot name="filter-title">
        {{ $t('Domyślna nazwa filtru') }}
      </slot>
      <div class="collapse__separator"></div>
      <SfButton class="collapse__button" rounded square>
        <IconArrow :class="{ 'collapse__arrow--open': open }" class="collapse__arrow" />
      </SfButton>
    </div>
    <div :class="{ 'collapse__content--open': open }" class="collapse__content">
      <slot name="filter-content" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .collapse {
    width: 100%;

    &__title {
      display: none;
      font-weight: bold;

      @media (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__separator {
      height: 1px;
      width: 100%;
      background: var(--color-medium-gray);
      margin-left: 0.5rem;
    }

    &__arrow {
      color: var(--color-black);
      transition: transform 0.3s ease;

      &--open {
        transform: rotate(180deg);
      }
    }

    &__content {
      @media (max-width: 992px) {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.8s ease-in-out;
        padding: 10px 10px 0 10px;
        display: flex;

        &--open {
          max-height: 500px;
          overflow: auto;
        }
      }
    }

    &--dark {
      .collapse__separator {
        background: var(--color-dark-gray);
        margin-left: 0;
      }

      .collapse__button {
        background: transparent;
        border: 1px solid var(--color-dark-gray);
      }

      .collapse__arrow {
        color: var(--primary-color);
      }
    }
  }
</style>
