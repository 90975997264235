<script setup>
  import { ref } from 'vue';

  defineProps({
    breadcrumbs: { type: Array, required: false, default: [] },
    sections: Array,
    recommended: Array,
    post: Object,
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      },
    },
  });
</script>

<template>
  <main class="container">
    <article>
      <div class="wrapper title-wrapper">
        <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
        <div class="title-container">
          <header>
            <h1 class="title" v-html="post.title" />
            <div class="subtitle" v-html="post.description_short" />
          </header>
          <div class="meta-data">
            <VisitorLink v-if="post.category" :href="route('web.blog.category.show', { category: post.category })" :title="post.category.title">
              {{ post.category.title }}
            </VisitorLink>
            <span v-if="post.date" class="divider">
              |
            </span>
            <span v-if="post.date">
              <SfDateTime :value="post.date" />
            </span>
            <span v-if="post.author" class="divider">
              |
            </span>
            <span v-if="post.author" class="author">
              {{ post.author.name }}
            </span>
          </div>
        </div>
        <div v-if="post.photos.filter(p => p.type === 'banner').length > 0" class="image-wrapper">
          <MediaPicture :photo="post.photos" sizes="2200x880 2x, 1100x440" type="banner" />
        </div>
      </div>
      <div class="wrapper content-wrapper">
        <div class="text-wrapper">
          <SectionRenderer :sections="sections" />
        </div>
        <article v-if="recommended.length > 0" class="recommended-wrapper">
          <SfHeading class="recommended-title" larger type="h2">
            {{ $t('Przeczytaj także') }}
          </SfHeading>
          <SfArticlesCarousel :parameters :posts="recommended" />
        </article>
      </div>
    </article>
  </main>
</template>
<style lang="scss" scoped>
  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1100px;
    margin: 60px auto;

    .breadcrumbs-wrapper {
      margin: 0;
    }

    .title-container {
      text-align: center;
      padding-bottom: 5px;

      .title {
        padding-bottom: 20px;
        font-size: 32px;
        font-weight: 600;
      }

      .meta-data {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        font-size: 13px;
        padding: 10px 0 5px 0;
        color: var(--color-dark-gray);

        @media (max-width: 576px) {
          flex-direction: column;
          gap: 0.25rem;
          .divider {
            display: none;
          }
        }

        a {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .author {
          white-space: nowrap;
        }
      }
    }

    .image-wrapper {
      width: 100%;
      aspect-ratio: 5/2;

      :deep(picture) {
        display: block;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          aspect-ratio: 5/2;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }
  }

  .content-wrapper {
    max-width: 1100px;
    margin: 0 auto 80px;
    background-color: var(--color-white);
    border-radius: 5px;
    padding: 50px;
    font-family: var(--font-family-sans);

    .text-wrapper p {
      font-size: 1rem;
      line-height: 1.5;
      padding-top: 15px;
      font-family: var(--font-family-sans);
    }

    .recommended-wrapper {
      margin-top: 2rem;

      .recommended-title {
        margin: 30px 0;
      }
    }
  }

  @media (max-width: 992px) {
    .title-wrapper {
      margin: 1rem;

      .title-container {
        font-size: 14px;

        .title {
          padding: 16px 0 10px 0;
          font-size: 1.5rem;
        }

        .meta-data {
          font-size: 12px;
        }
      }

      .image-wrapper {
        width: 95%;
      }
    }
    .content-wrapper {
      padding: 12px 24px 36px 24px;
      margin-bottom: 0;

      .text-wrapper {
        padding-bottom: 1rem;
        font-size: 0.75rem;
      }

      .recommended-wrapper {
        .section-title {
          margin: 20px 0;
        }
      }
    }
  }
</style>
