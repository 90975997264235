<script lang="ts" setup>
  import { useBreakpoints } from '$storefront';
  import { route } from '@plenny/visitor';
  import { computed } from 'vue';

  const props = defineProps({
    product: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: false,
      default: 'h2',
    },
    horizontal: {
      type: Boolean,
    },
  });

  const breakpoints = useBreakpoints();

  const target = computed(() => {
    return route('web.product.show', { slug: props.product.slug });
  });

  const hasListAttributes = computed(() => {
    return props.product.list_attributes && props.product.list_attributes.length > 0;
  });
</script>
<template>
  <article :class="{ horizontal, 'has-labels': product.is_new || product.discount_value }" class="product" @click="$redirect(target)">
    <div class="product-wrapper">
      <MediaPicture :photo="product.photo" sizes="490x368 2x, 245x184" />
      <div class="product-content">
        <div class="info-wrapper">
          <header>
            <component :is="header" class="title">
              <VisitorLink :href="target">
                {{ product.title }}
              </VisitorLink>
            </component>
          </header>
          <dl v-if="hasListAttributes" v-show="horizontal" class="detailed-data-wrapper">
            <div v-for="attribute in product.list_attributes" :key="attribute.name" class="details-column">
              <dt class="detail-property">{{ attribute.name }}:</dt>
              <dd :title="attribute.value" class="detail-value">{{ attribute.value }}</dd>
            </div>
          </dl>
        </div>
        <div class="actions-wrapper">
          <div class="icons-wrapper">
            <slot name="icons">
              <SfWishlistButton :variant="product.variant_id" />
            </slot>
          </div>
          <div class="price-cart">
            <div class="price-button">
              <div class="price-wrapper product-card-price-wrapper">
                <SfProductPrice :price="product" class="product-card-price" />
              </div>
              <SfCartButton :product="product">
                <SfButton :square="!horizontal || horizontal && !breakpoints.lg">
                  <template v-if="horizontal && breakpoints.lg" #default>
                    {{ $t('Dodaj do koszyka') }}
                  </template>
                  <template #after>
                    <IconCart />
                  </template>
                </SfButton>
              </SfCartButton>
            </div>
            <div class="omnibus-wrapper">
              <SfProductOmnibus :price="product" />
              <SfProductUnitPrice :price="product" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="labels-wrapper">
      <SfProductTag :visible="product.is_new" tag-type="novelty">
        {{ $t('Nowość') }}
      </SfProductTag>
      <SfProductTag :visible="+product.price_gross !== +product.regular_gross" tag-type="discount">
        {{ $t('Promocja') }}
      </SfProductTag>
    </div>
  </article>
</template>
<style lang="scss" scoped>
  .product {
    display: flex;
    position: relative;
    flex-grow: 1;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    transition: var(--transition-primary);
    cursor: pointer;

    .product-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding: 15px;
      width: 100%;

      > :deep(picture) {
        width: 100%;
      }

      :deep(a) {
        text-decoration: none;
      }

      .product-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;

        .title {
          width: 100%;
          margin: 25px 0 15px;
          font-size: 1rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .icons-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: auto;
          position: absolute;
          right: 15px;
          transition: var(--transition-primary);
          background: var(--color-white);
          border-radius: 50%;
          top: 15px;
          opacity: 1;

          @media (max-width: 576px) {
            right: 5px;
          }
        }

        .price-cart {
          display: flex;
          flex-direction: column;

          .price-button {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .product-card-price-wrapper {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              column-gap: 7px;

              > :deep(.price) {
                > .price__catalog {
                  font-size: 0.875rem;
                }

                > .price__current {
                  font-size: 1.125rem;
                  font-weight: 700;
                  line-height: 1;
                }
              }
            }
          }

          :deep(.omnibus) {
            margin-top: 15px;
          }
        }
      }
    }

    &:hover {
      box-shadow: var(--box-shadow-primary)
    }

    &:hover .product-wrapper .product-content .actions-wrapper .icons-wrapper {
      opacity: 1;
    }

    &.horizontal {
      width: 100%;

      > .product-wrapper {
        flex-direction: row;
        width: 100%;

        > :deep(picture ) {
          width: unset;

          > img {
            width: 184px;
          }
        }

        > .product-image {
          width: 160px;
        }

        > .product-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          > .info-wrapper {
            display: flex;
            flex-direction: column;
            padding: 0 40px;

            > .title {
              font-size: 18px;
              font-weight: 600;
              margin: 0;
            }

            > .detailed-data-wrapper {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              column-gap: 50px;
              row-gap: 5px;
              font-size: 14px;
              margin-top: 20px;

              @media(max-width: 1400px) {
                grid-template-columns: 1fr;
                font-size: 12px;
              }

              .details-column {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }

              .detail-property {
                display: inline;
                font-weight: 500;
                margin-right: 4px;
              }

              .detail-value {
                display: inline;
                color: var(--color-dark-gray);
              }
            }
          }

          > .actions-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            > .icons-wrapper {
              flex-direction: row;
              position: static;
              opacity: 1;
            }

            > .price-cart {
              align-items: flex-end;

              > .price-button {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                row-gap: 10px;

                > .product-card-price-wrapper {
                  > :deep(.price) {
                    > .price__catalog {
                      font-size: 1rem;
                    }

                    > .price__current {
                      font-size: 1.375rem;
                    }
                  }
                }
              }

              > .omnibus-wrapper {
                margin-top: 5px;
              }
            }
          }
        }
      }

      > .labels-wrapper {
        justify-content: flex-start;
      }
    }
  }

  .labels-wrapper {
    position: absolute;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    top: 0;
    width: 100%;
    transform: translateY(-50%);
    padding: 0 15px;
    font-size: 14px;
    line-height: 17px;
  }

  @media (max-width: 1200px) {
    .labels-wrapper {
      padding: 0 5px;
    }

    .product.horizontal {
      &.has-labels .product-wrapper {
        padding: 15px;
      }

      > .product-wrapper {
        flex-direction: column;
        align-items: center;

        .product-content {
          width: 100%;
          flex-direction: column;

          .actions-wrapper {
            margin-top: 20px;

            .price-cart {
              align-items: flex-start;
              width: 100%;

              .price-button {
                width: 100%;
                flex-direction: row;
              }

              :deep(.omnibus) {
                margin-top: 5px;
              }
            }
          }

          .info-wrapper .title {
            margin-top: 15px;
            font-size: 16px;
            font-weight: 700;
          }

          .actions-wrapper,
          .info-wrapper {
            padding: 0;
          }

          .actions-wrapper .icons-wrapper {
            position: absolute;
            top: 20px;
            right: 18px;
          }
        }

        > :deep(img) {
          height: 180px;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .product {
      width: 100%;
      justify-content: center;

      .labels-wrapper {
        justify-content: flex-start;
        transform: translateY(0);
        padding: 0;
        top: 4px;
        left: 4px;
      }

      .product-wrapper .product-content .title {
        margin-top: 15px;
      }
    }
  }

  @media (max-width: 768px) {
    .product, .product.horizontal {
      > .product-wrapper {
        width: 100%;

        > .product-content > .actions-wrapper > .price-cart > .price-button > .product-card-price-wrapper > :deep(.price) {
          > .price__catalog {
            font-size: 0.75rem;
          }

          > .price__current {
            font-size: 1rem;
          }
        }
      }
    }
  }
</style>

