<script setup lang="ts">
import { useSession, route } from '@plenny/visitor';

const session = useSession();
</script>
<template>
  <div class="action-item">
    <VisitorLink :href="session.is_authenticated ? route('web.account.wishlist.index') : route('web.account.session.create')" class="link">
      <IconHeart  clss="wishlist-header-icon"/>
      <span class="text">{{ $t('Ulubione') }}</span>
    </VisitorLink>
  </div>
</template>
<style scoped lang="scss">
.action-item {
  height: 100%;

  &.scroll {
    .link {
      column-gap: 0;

      .text {
        font-size: 0;
        opacity: 0;
      }
    }
  }

  .wishlist-header-icon{
    fill: red;
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    font-size: 24px;
    height: 100%;
    color: var(--color-primary-black);

    &:hover {
      color: var(--primary-color);
    }

    .text {
      font-size: 16px;

      transition: var(--transition-header-scroll);

      @media(max-width: 1200px) {
        display: none;
      }
    }
  }
}
</style>
