<script setup>
  import { computed } from 'vue';
  import { route } from '@plenny/visitor';

  const props = defineProps({
    parent: { type: Object, required: false },
    category: { type: Object, required: false },
    categories: Array,
  });

  const title = computed(() => {
    if (props.parent) {
      return props.parent.title;
    } else if (props.category) {
      if (props.category._rgt === (props.category._lft + 1)) {
        return null;
      } else {
        return props.category.title;
      }
    } else {
      return null;
    }
  });

  const back = computed(() => {
    if (props.parent) {
      return route('web.blog.category.show', { category: props.parent });
    } else if (props.category) {
      return route('web.blog.post.index');
    } else {
      return null;
    }
  });
</script>
<template>
  <div class="filters-wrapper">
    <div class="block">
      <div class="title">
        <SfHeading type="h2">
          {{ $t('Kategorie') }}
        </SfHeading>
      </div>
      <div class="filters-content">
        <VisitorLink v-if="back" :href="back" class="filters-back">
          {{ $t('Wróć do') + ':' }}&nbsp;{{ parent?.title || $t('Wszystkie') }}
        </VisitorLink>
        <ul>
          <li v-for="category in categories">
            <VisitorLink :href="route('web.blog.category.show', { category })">
              {{ category.title }}
            </VisitorLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .filters-wrapper {
    background-color: var(--color-white);
    border-radius: 0.375rem;
  }

  .block {
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 1.125rem;
    padding: 40px 60px;
    border: 1px solid var(--color-dark-gray);
    border-radius: 1.125rem;
    line-height: 1.75;
    height: fit-content;

    .filters-content a {
      transition: color 0.3s ease-out;

      &:hover {
        color: var(--primary-color);
      }
    }

    .title {
      position: absolute;
      top: 0;
      left: 1.25rem;
      transform: translateY(-50%);
      background-color: var(--color-white);
      padding: 0.125rem 1.25rem 0.125rem 1.25rem;

      .section-title {
        font-weight: 600;

        &::after {
          background-color: var(--color-medium-gray)
        }
      }
    }

    .filters-back {
      display: inline-flex;
      padding-bottom: 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
    }

    a.active {
      font-weight: 700;
    }
  }

  @media (max-width: 992px) {
    .block {
      padding: 1.75rem 2rem;

      .filters-content {
        font-size: 0.875rem;
        line-height: 2;

        ul {
          max-height: 40svh;
          overflow-y: auto;
          list-style: none;

          li::before {
            content: '';
            display: inline-block;
            height: 0.25rem;
            width: 0.25rem;
            background: black;
            border-radius: 0.125rem;
            vertical-align: middle;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .block {
      padding: 1.75rem 1.5rem;
    }
  }
</style>
