<script lang="ts" setup>
  import { computed } from 'vue';

  const props = defineProps({
    larger: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'div', validator: (v) => ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(v) },
    gray: { type: Boolean, default: false },
  });

  const classes = computed(() => {
    return {
      'heading--gray': props.gray,
      'heading--large': props.large,
      'heading--larger': props.larger,
    };
  });
</script>
<template>
  <component :is="props.type" :class="classes" class="heading">
    <slot />
  </component>
</template>
<style lang="scss" scoped>
  .heading {
    display: block;
    position: relative;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 2rem;
    padding: 0 0.75rem;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0.25rem;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      background-color: var(--primary-color);
      z-index: -1;
    }

    &--gray {
      &::after {
        background-color: var(--color-dark-gray);
      }
    }

    &--large {
      font-size: 1.75rem;
      line-height: 2.5rem;
      padding: 0 1rem;

      &::after {
        height: 2rem;
        width: 2rem;
      }
    }

    &--larger {
      font-size: 2rem;
      line-height: 3rem;
      padding: 0 1.25rem;

      &::after {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }
</style>
