<script lang="ts" setup>
  import { useShared } from '@plenny/visitor';
  import { trans } from '@plenny/translator';

  const shared = useShared();

  const days = {
    mon: trans('poniedziałek'),
    tue: trans('wtorek'),
    wed: trans('środa'),
    thu: trans('czwartek'),
    fri: trans('piątek'),
    sat: trans('sobota'),
    sun: trans('niedziela'),
  };
</script>
<template>
  <footer>
    <div class="container footer-top">
      <Collapse v-for="group in shared.menu.menu_bottom" class="footer-top-collapse" dark>
        <template #filter-title>
          <SfHeading gray>{{ group.name }}</SfHeading>
        </template>
        <template #filter-content>
          <div class="block categories">
            <div class="title">
              <SfHeading gray>{{ group.name }}</SfHeading>
            </div>
            <ul>
              <li v-for="item in group.children">
                <VisitorLink
                  :explicit="item.explicit"
                  :href="item.url"
                  :rel="item.rel"
                  :target="item.target"
                  class="link">
                  {{ item.name }}
                </VisitorLink>
              </li>
            </ul>
          </div>
        </template>
      </Collapse>
      <div v-if="shared.department" class="block contact">
        <div class="title">
          <SfHeading gray>{{ $t('Kontakt') }}</SfHeading>
        </div>
        <div class="contact-content">
          <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="phone-link">
            <IconPhone />
            <span v-html="shared.department.phone" />
          </a>
          <a v-if="shared.department.email" :href="`mailto:${shared.department.email}`" class="mail-link">
            <IconEnvelope />
            <span v-html="shared.department.email" />
          </a>
          <a :href="shared.department.maps || '#'" class="address-link" target="_blank">
            <span v-if="shared.department.first_line" v-text="shared.department.first_line" />
            <span v-if="shared.department.second_line" v-text="shared.department.second_line" />
            <span v-if="shared.department.third_line" v-text="shared.department.third_line" />
          </a>
          <div class="footer-hours">
            <p class="footer-hours-title">{{ $t('Godziny otwarcia:') }}</p>
            <template v-for="day in ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']">
              <div v-if="shared.department.hours[day]" class="opening-hours">
                <span><b>{{ days[day] }}</b></span>
                <span v-if="shared.department.hours[day].closed">{{ $t('zamknięte') }}</span>
                <span v-else>{{ shared.department.hours[day].from }}&nbsp;-&nbsp;{{ shared.department.hours[day].to }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <Separator />
    <div class="container footer-bottom">
      <div>
        <CopyrightMessage />
      </div>
      <div>
        <p>
          {{ $t('Realizacja') + ': ' }}<a class="bottom-link" href="https://promoznawcy.pl/" target="_blank">PROMOznawcy.pl</a>
        </p>
      </div>
    </div>
    <FixedActions />
  </footer>
</template>
<style lang="scss" scoped>
  footer {
    background-color: var(--color-primary-black);
    padding-top: 90px;
    margin-top: 80px;

    .footer-top {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      padding-bottom: 60px;
      color: var(--color-medium-gray);
      font-size: 0.875rem;

      a {
        color: var(--color-medium-gray);
        transition: var(--transition-primary);

        &:hover {
          color: var(--primary-color);
        }
      }

      .footer-top-collapse {
        :deep(.collapse__title) {
          padding-left: 1rem;

          .section-title {
            flex: 1 0 auto;
            padding-right: 0.25rem;
            word-break: keep-all;
          }
        }

        :deep(.collapse__content) {
          @media (max-width: 992px) {
            padding: 0;
          }
        }

        .title {
          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      .block {
        display: flex;
        flex-direction: column;
        position: relative;
        row-gap: 0.375rem;
        padding: 2.5rem 2rem;
        border: 1px solid var(--color-dark-gray);
        border-radius: 0.625rem;
        height: fit-content;
        flex: 1 1 auto;

        ul {
          list-style: none;
          display: flex;
          max-height: 300px;
          flex-direction: column;
          flex-wrap: wrap;
          row-gap: 0.5rem;

          li {
            padding-right: 1rem;
            max-width: 50%;
          }
        }

        .title {
          position: absolute;
          top: 0;
          left: 1.25rem;
          transform: translateY(-50%);
          background-color: var(--color-primary-black);
          padding: 0.125rem 0.626rem 0.125rem 1.25rem;
        }

        .contact-content {
          .phone-link,
          .mail-link {
            display: flex;
            column-gap: 1rem;
            margin-bottom: 1rem;
            align-items: center;
            font-size: 1.125rem;
            color: var(--color-medium-gray);
            transition: var(--transition-primary);

            &:hover {
              color: var(--primary-color);
            }

            svg {
              color: var(--primary-color);
            }
          }

          .address-link {
            display: block;
            margin-top: 1.25rem;
            margin-bottom: 1.875rem;

            span {
              display: block;
            }
          }

          .footer-hours {
            font-size: 0.875rem;

            .footer-hours-title {
              margin-bottom: 0.5rem;
            }

            .opening-hours {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 1.5rem;
            }
          }
        }
      }
    }

    .separator {
      background-color: var(--color-dark-gray);
    }

    .footer-bottom {
      display: flex;
      justify-content: space-between;
      color: var(--color-dark-gray);
      font-size: 1rem;
      padding: 10px 0;

      .bottom-link {
        color: var(--color-medium-gray);
        transition: var(--transition-primary);

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }

  @media (max-width: 1400px) {
    footer .footer-top {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 992px) {
    footer {
      padding-top: 2.5rem;
      margin-top: 2rem;

      .footer-top {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0.75rem;
        margin-bottom: 2rem;

        .block {
          padding: 1.5rem 0 2rem 2.25rem;

          &.categories {
            border: none;
            border-radius: 0;
            padding: 1.5rem 0 0 2.25rem;
          }

          &.contact {
            order: -1;
          }
        }
      }

      .footer-bottom {
        flex-direction: column;
        align-items: center;
        font-size: 0.75rem;
        gap: 0.125rem;
      }
    }
  }
</style>
