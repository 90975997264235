<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 14" height="1em">
    <g transform="translate(3 -5)">
      <g transform="translate(3 5)">
        <rect width="16" height="2" rx="1" />
        <rect width="16" height="2" rx="1" transform="translate(0 6)" />
        <rect width="16" height="2" rx="1" transform="translate(0 12)" />
      </g>
      <g transform="translate(-3 5)">
        <rect width="4" height="2" rx="1" />
        <rect width="4" height="2" rx="1" transform="translate(0 6)" />
        <rect width="4" height="2" rx="1" transform="translate(0 12)" />
      </g>
    </g>
  </svg>
</template>
