<script setup>
import HeaderAccountDetailsMini from './HeaderAccountDetailsMini.vue';
import { useSession, route } from '@plenny/visitor';
import { ref } from 'vue';

const session = useSession();
const mouseOver = ref(false);

</script>
<template>
  <div @mouseover="mouseOver = true" @mouseleave="mouseOver = false" class="action-item">
    <VisitorLink :href="session.is_authenticated ? route('web.account.show') : 'javascript:void(0)'" class="link">
      <IconPersonCircle />
      <span class="text">{{ $t('Konto') }}</span>
    </VisitorLink>
    <Transition name="show">
      <HeaderAccountDetailsMini v-if="mouseOver && !session.is_authenticated" />
    </Transition>
  </div>
</template>
<style scoped lang="scss">
.show-enter-from,
.show-leave-to {
  opacity: 0;
}

.show-enter-active,
.show-leave-active {
  transition: var(--transition-primary-popups);
}

.action-item {
  position: relative;
  height: 100%;

  &.scroll {
    .link {
      column-gap: 0;

      .text {
        font-size: 0;
        opacity: 0;
      }
    }
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    column-gap: 10px;
    height: 100%;
    color: var(--color-primary-black);

    &:hover {
      color: var(--primary-color);
    }

    .text {
      font-size: 16px;
      transition: all 0.2s;

      @media(max-width: 1200px) {
        display: none;
      }
    }
  }
}
</style>
