<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="738" height="135" viewBox="0 0 738 135" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M88.0616 131.113C81.4854 133.267 74.4933 134.401 67.199 134.401C30.0846 134.401 0 104.316 0 67.2019C0 53.4824 4.11962 40.7456 11.1494 30.1253L41.2718 52.4998C40.1002 60.2099 42.9726 68.109 49.0575 73.3246C47.0922 86.5528 49.3977 100.801 55.596 111.195C61.9833 121.891 73.5863 129.299 86.6632 130.962C87.1168 131.037 87.6081 131.075 88.0616 131.113Z" fill="#2B2A29"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M118.864 110.212C116.521 113.009 113.951 115.617 111.192 118.036C103.066 120.946 95.0915 122.042 87.9483 121.135C77.8571 119.85 68.9375 114.218 64.0998 106.13C59.6022 98.6093 57.6747 88.2535 58.4684 78.2757C63.6841 79.6741 69.1643 79.0316 73.8508 76.764L118.864 110.212Z" fill="#2B2A29"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M67.199 0.00292969C104.313 0.00292969 134.398 30.0875 134.398 67.2019C134.398 82.6977 129.182 96.9463 120.376 108.285L78.9531 73.3246C80.7295 71.7373 82.279 69.8475 83.4885 67.6554L90.4805 55.1454C91.9923 52.4998 91.0474 49.136 88.364 47.6242C87.268 47.0195 86.89 45.6211 87.4947 44.5251L92.3324 35.8701C93.4663 33.867 92.7482 31.3347 90.7451 30.2009C88.7419 29.067 86.2097 29.7851 85.0759 31.7883L80.087 40.6322C79.52 41.6149 78.3106 41.955 77.3279 41.4259L69.2399 36.8906C68.2572 36.3236 67.9171 35.0764 68.4462 34.0937L73.3973 25.2876C74.5689 23.2467 73.813 20.6766 71.8099 19.5428L71.7343 19.505C69.769 18.4089 67.3123 19.0893 66.2163 21.0546L61.303 29.8229C60.6605 30.919 59.2999 31.2969 58.2038 30.6922C55.5204 29.1804 52.1567 30.1253 50.6449 32.7709C48.9819 35.7567 46.6764 39.8385 44.2954 44.0715L17.7635 21.6971C30.0468 8.35556 47.6591 0.00292969 67.199 0.00292969Z" fill="#B0CB1F"/>
    <path d="M151.632 116.146V32.2418H220.608V53.2934H188.747V68.5247H212.368V89.5764H188.747V116.146H151.632Z" fill="#2B2A29"/>
    <path d="M224.463 77.1797V57.2619C234.063 52.0084 244.947 49.4006 257.08 49.4006C268.682 49.4006 277.224 51.8573 282.742 56.8084C288.222 61.7973 290.981 69.3562 290.981 79.523V116.146H257.004V111.195C251.977 114.861 246.459 116.675 240.45 116.675C235.385 116.675 231.152 115.05 227.826 111.762C224.425 108.474 222.762 104.354 222.762 99.3652C222.762 93.696 224.841 89.0472 228.998 85.4945C233.193 81.904 238.673 80.1277 245.401 80.1277C249.332 80.1277 253.224 80.619 257.004 81.6773C257.004 78.5781 255.794 76.197 253.451 74.4585C251.108 72.7577 247.782 71.8884 243.587 71.8884C237.351 71.8884 230.963 73.627 224.463 77.1797ZM257.004 101.519V92.2598C254.925 91.5795 253.073 91.2393 251.448 91.2393C249.369 91.2393 247.744 91.7684 246.497 92.8267C245.25 93.8849 244.607 95.2834 244.607 96.9841C244.607 98.7983 245.288 100.31 246.61 101.519C247.971 102.729 249.672 103.334 251.713 103.334C253.829 103.334 255.568 102.729 257.004 101.519Z" fill="#2B2A29"/>
    <path d="M369.027 93.9605V116.146H298.162L322.086 71.1325H301.262V49.9675H369.368L345.935 93.9605H369.027Z" fill="#2B2A29"/>
    <path d="M409.165 117.129C398.696 117.129 390.041 113.954 383.238 107.642C376.435 101.293 373.034 93.0913 373.034 82.9245C373.034 72.7577 376.397 64.5185 383.087 58.1312C389.814 51.7439 398.507 48.5691 409.165 48.5691C419.899 48.5691 428.63 51.7439 435.357 58.1312C442.085 64.5185 445.448 72.7577 445.448 82.9245C445.448 93.0535 442.085 101.255 435.357 107.604C428.63 113.954 419.899 117.129 409.165 117.129ZM409.165 74.6852C406.935 74.6852 405.159 75.4033 403.799 76.8018C402.4 78.2002 401.72 80.0521 401.72 82.282C401.72 84.5119 402.4 86.326 403.799 87.7244C405.159 89.1228 406.935 89.8031 409.165 89.8031C411.395 89.8031 413.209 89.1228 414.57 87.7244C416.006 86.326 416.686 84.5119 416.686 82.282C416.686 80.0143 416.006 78.1624 414.57 76.764C413.209 75.3656 411.395 74.6852 409.165 74.6852Z" fill="#2B2A29"/>
    <path d="M445.335 49.9675H480.106L486.644 64.1405L492.578 49.9675H508.376L514.575 64.1405L521.302 49.9675H556.527L527.16 116.146H508.263L500.402 98.0046L492.503 116.146H473.568L445.335 49.9675Z" fill="#2B2A29"/>
    <path d="M603.732 134.703H566.769L580.413 102.502L557.094 49.9675H590.92L598.895 67.5798L606.529 49.9675H639.524L603.732 134.703Z" fill="#2B2A29"/>
    <path d="M642.737 106.962C642.737 104.241 643.719 101.897 645.647 99.9699C647.574 98.0424 649.918 97.0597 652.639 97.0597C655.36 97.0597 657.703 98.0424 659.631 99.9699C661.558 101.897 662.541 104.241 662.541 106.962C662.541 109.683 661.558 112.026 659.631 113.992C657.703 115.919 655.36 116.902 652.639 116.902C649.918 116.902 647.574 115.919 645.647 113.992C643.719 112.026 642.737 109.683 642.737 106.962Z" fill="#B0CB1F"/>
    <path d="M687.75 79.523V82.3198C690.585 80.2411 693.608 79.1828 696.783 79.1828C701.394 79.1828 705.211 80.9214 708.273 84.3229C711.334 87.7622 712.883 92.0708 712.883 97.2487C712.883 102.805 711.221 107.415 707.932 111.006C704.644 114.634 700.449 116.448 695.347 116.448C692.814 116.448 690.282 115.881 687.75 114.823V126.426H668.966V79.523H687.75ZM687.75 95.6235V102.238C688.846 102.804 689.942 103.107 691.038 103.107C692.134 103.107 693.041 102.691 693.759 101.86C694.477 101.028 694.818 99.9321 694.818 98.5715C694.818 97.2109 694.477 96.077 693.759 95.2078C693.041 94.3385 692.134 93.8849 691.038 93.8849C689.753 93.8849 688.657 94.4897 687.75 95.6235Z" fill="#B0CB1F"/>
    <path d="M737.601 116.146H718.779V69.6963H737.601V116.146Z" fill="#B0CB1F"/>
  </svg>
</template>