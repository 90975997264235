<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    item: Object,
    depth: Number,
    index: String,
    category: Boolean,
  });

  const component = computed(() => {
    if (props.item.url) {
      return 'VisitorLink';
    } else {
      return 'span';
    }
  });

  const specific = computed(() => {
    if (props.item.url) {
      return {
        href: props.item.url,
        rel: props.item.rel,
        target: props.item.target,
        explicit: props.item.explicit,
      };
    } else {
      return {};
    }
  });

  const level = computed(() => {
    return `level-${props.depth}`;
  });

  const children = computed(() => {
    return `nav-children-${props.depth}-${props.index}`;
  });
</script>
<template>
  <li class="nav-item" :class="level" @click="$emit('close')">
    <component :is="component" v-bind="specific" :class="{ category }" tabindex="0" aria-haspopup="true" :aria-controls="children">
      <span>
        {{ item.name }}
        <small class="category-available" v-if="depth !== 0 && item.available">
          ({{ item.available }})
        </small>
      </span>
      <IconChevronRight v-if="depth === 0 && item.children.length > 0" class="nav-item-arrow" />
    </component>
    <div v-if="category || item.children.length > 0" :id="children" class="children-wrapper">
      <ul class="children">
        <HeaderNavItem
          v-for="(item, i) in item.children"
          :item="item"
          :depth="depth + 1"
          :index="`${index}-${i}`"
          @close="$emit('close')"
        />
      </ul>
    </div>
  </li>
</template>
<style scoped lang="scss">
  .nav-item {
    .category-available {
      color: var(--color-gray);
      font-size: 12px;
      font-weight: 400;
      transition: var(--transition-primary);
    }

    &:hover, &:focus-within {
      &.level-0 > .children-wrapper {
        display: block;
        border-bottom: 1px solid var(--color-medium-gray);
        border-right: 1px solid var(--color-medium-gray);
      }
    }

    &.level-2 {
      font-size: 15px;

      .children-wrapper {
        display: none;
      }
    }

    &.level-0 {
      > .children-wrapper {
        position: absolute;
        display: none;
        top: 0;
        left: 299px;
        right: 0;
        height: 100%;
        padding: 15px 15px 15px 50px;
        background-color: var(--color-white);
        box-shadow: var(--box-shadow-menu);
        border-bottom-right-radius: 5px;

        > .children {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: auto;
          gap: 32px 16px;
          max-height: 100%;
          overflow-y: auto;
          overscroll-behavior: contain;
          list-style: none;
          margin: 0;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background-color: var(--color-light-background);
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--primary-color);
            border-radius: 4px;
          }

          @media(max-width: 1650px) {
            grid-template-columns: repeat(3, 1fr);
          }

          @media(max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
          }

          & > li > a {
            font-weight: 600;
            font-size: 15px;
          }
        }
      }

      > a {
        position: relative;

        &.category {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 9px 0 9px 20px;
          align-items: center;
          font-weight: 600;
        }

        & svg {
          transform: translateX(-8px);
          font-size: 9px;
          transition: var(--transition-primary);
        }
      }

      &:hover {
        > a.category {
          color: var(--primary-color);

          > svg {
            transform: translateX(0);
          }
        }
      }
    }

    a {
      display: block;
      padding: 0.25rem 0;
      transition: var(--transition-primary);

      &:hover,
      &.active,
      &:hover .category-title .category-available {
        color: var(--primary-color);
      }
    }

    ul {
      list-style: none;
    }
  }
</style>
