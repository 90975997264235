<script lang="ts" setup>
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    header: String,
    brands: Array,
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 3.5,
      },
      576: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 5,
      },
      992: {
        slidesPerView: 6,
      },
    },
  });

</script>

<template>
  <Section :link="route('web.brand.index')" :title="header">
    <SfBrandsCarousel :brands :parameters class="brands-carousel--frame" />
  </Section>
</template>

<style lang="scss" scoped>

  @media(max-width: 576px) {
    .section-wrapper {
      padding-right: 0;
    }
  }
</style>

<style lang="scss">

  .brands-carousel--frame {
    --sf-carousel-button-space: 0;
    --sf-carousel-button-next-translateX: 50%;
    --sf-carousel-button-prev-translateX: -50%;

    .brands-carousel__container {
      border: 1px solid var(--color-medium-gray);
      border-radius: 5px;
      padding: 1rem
    }
  }
</style>
