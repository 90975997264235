<script setup lang="ts">
  import { onMounted, ref, type Ref, onBeforeUpdate, onUpdated, onBeforeUnmount } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';

  const props = defineProps({
    perView: { type: Number, required: true, default: 8 },
    categories: Array,
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Navigation],
      slidesPerView: 1.5,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
        1400: {
          slidesPerView: 6,
        },
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel categories-carousel categories-carousel--frame">
    <div ref="htmlElement" class="swiper items-carousel__container categories-carousel__container">
      <div class="swiper-wrapper">
        <div class="swiper-slide items-carousel__slide categories-carousel__article" v-for="category in categories">
          <VisitorLink :href="route('web.product.category', { category })" class="categories-carousel__link">
            <MediaPicture :photo="category.photo" sizes="400x300 2x, 200x150" />
            <span>{{ category.title }}</span>
          </VisitorLink>
        </div>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
<style scoped lang="scss">
  .categories-carousel {
    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      box-shadow: var(--box-shadow-primary);
      border-radius: 5px;
      padding: 1.5rem 1rem;
      flex-grow: 1;
      text-align: center;
      line-height: 1.5;

      :deep(img) {
        height: 150px;
      }

      span {
        transition: var(--transition-primary);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        word-break: break-word;
      }

      &:hover span {
        color: var(--primary-color);
      }
    }
  }

  @media(max-width: 768px) {
    .categories-carousel__link {
      padding: 1rem 0.5rem;

      :deep(img) {
        height: 100px;
      }
    }
  }
</style>

