<script lang="ts" setup>
  import { ref, watch } from 'vue';
  import { useShared, route } from '@plenny/visitor';
  import { useBreakpoints, useScrollDetector } from '$storefront';

  const headerBottom = ref();

  const scroll = ref(false);
  const show = ref(false);
  const showMenu = ref(false);
  const searchMobilebar = ref(false);

  const breakpoints = useBreakpoints();
  const shared = useShared();

  useScrollDetector({
    threshold: 8,
    callback: ({ up }) => {
      if (up) {
        headerBottom.value.classList.add('header__bottom--open');
        scroll.value = false;
      } else {
        headerBottom.value.classList.remove('header__bottom--open');
        scroll.value = true;
      }
    },
  });

  watch(scroll, () => {
    if (!scroll.value) {
      show.value = false;
      searchMobilebar.value = false;
    }
  });
</script>
<template>
  <header :class="{ 'header--scroll': scroll, 'search-mobile-active': searchMobilebar }" class="header">
    <div class="header__top">
      <div class="container header__top__container">
        <VisitorLink :href="route('web.homepage.index')" class="header__top__logo">
          <SfLogo class="header__top__logo__img" />
        </VisitorLink>
        <HeaderSearch v-if="breakpoints.lg" class="header-search" placeholder="Szukaj produktu..." />
        <div class="header__top__icons">
          <HeaderFavorite v-if="breakpoints.lg" :class="{ scroll }" />
          <HeaderAccount v-if="breakpoints.lg" :class="{ scroll }" />
          <MiniCart />
        </div>
      </div>
    </div>
    <div ref="headerBottom" class="header__bottom header__bottom--open">
      <div class="container header__bottom__inner">
        <div class="header__bottom__inner__wrapper">
          <IconHamburger v-if="!breakpoints.lg" class="icon-hamburger" @click="showMenu = true" />
          <HeaderSearch v-if="!breakpoints.lg" class="header-search" placeholder="Szukaj produktu..." />
          <Categories v-if="breakpoints.lg" compact />
          <nav v-if="breakpoints.lg" class="navigation">
            <ul>
              <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <MenuDrawer v-if="!breakpoints.lg" v-model:open="showMenu" />
</template>
<style lang="scss" scoped>

  @keyframes show-overflow {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }

  .icon-hamburger {
    font-size: 16px;
  }

  .fade-down-enter-active,
  .fade-down-leave-active {
    transition: var(--transition-primary-fade);
  }

  .fade-down-enter-from,
  .fade-down-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }

  .search-mobile-active.header {
    box-shadow: none;
  }

  .mobile-search {
    position: fixed;
    top: 49px;
    width: 100%;
    z-index: 997;
    padding: 5px 15px 10px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-primary);
  }

  .header-search {
    width: 40%;

    @media (max-width: 1200px) {
      width: 60%;
    }

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .header {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-primary);
    transition: var(--transition-header-scroll);

    &__top {
      &__container {
        position: relative;
        display: flex;
        height: 90px;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        padding: 15px 0;
      }

      &__logo {
        &__img {
          width: 100%;
          max-width: 250px;
          height: 50px;
        }
      }

      &__search {
        width: 40%;

        @media (max-width: 1200px) {
          width: 50%;
        }
      }

      &__icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 325px;
        column-gap: 30px;
        height: 100%;

        @media (max-width: 1200px) {
          min-width: 200px;
        }

        @media (max-width: 576px) {
          min-width: unset;
        }

        :deep(svg) {
          transition: var(--transition-primary);
        }
      }
    }
  }

  @media(max-width: 1400px) {
    .header {
      &__top {
        &__icons {
          column-gap: 20px;
        }
      }
    }
  }

  @media(max-width: 1200px) {
    .header {
      &__top {
        &__logo, &__icons {
          width: auto;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .header {
      &__top {
        &__container {
          padding: 10px 15px;
          height: 50px;
        }

        &__logo {
          &__img {
            width: 120px;
          }
        }

        &__icons {
          width: auto;
          gap: 16px;
        }
      }
    }
  }

  .header__bottom {
    display: grid;
    width: 100%;
    min-width: 0;
    grid-template-rows: 0fr;
    transition: grid-template-rows ease-in-out 100ms;
    border-top: 1px solid var(--color-medium-gray);

    @media(max-width: 992px) {
      border-top: none;
    }

    &--open {
      grid-template-rows: 1fr;

      .header__bottom__inner {
        animation: show-overflow ease-in-out 100ms;
        overflow: visible;
      }
    }

    &__inner {
      position: relative;
      display: flex;
      width: 100%;
      min-width: 0;
      overflow: hidden;

      &__wrapper {
        display: flex;
        width: 100%;
        min-width: 0;

        @media(max-width: 992px) {
          align-items: center;
          gap: 20px;
          padding: 10px 15px;
        }
      }
    }
  }

  .navigation {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 60px;
    padding-left: 42px;
    width: 100%;

    ul {
      display: flex;
      gap: 42px;
      padding: 0;
      margin: 0;
      list-style: none;

      li.nav-item {
        position: relative;

        :deep(.nav-item-arrow) {
          transform: rotate(0);
          font-size: 0.75rem;
          margin-left: 0.5rem;
        }

        :deep(> .children-wrapper) {
          top: 100%;
          left: 0;
          right: unset;
          height: auto;
          padding: 0.5rem;
          min-width: 300px;
          border-radius: 0;

          a {
            font-weight: 600;
            padding: 0.5rem 0;
          }

          .nav-item.level-2 .children-wrapper {
            display: flex;
            flex-direction: column;
          }

          .children {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            padding-left: 1rem;
          }

          @media(max-width: 1200px) {
            left: unset;
            right: 0;
          }
        }
      }
    }

    :deep(.level-0) {
      & > a {
        text-decoration: none;
        line-height: 22px;
        padding: 15px 0;
        color: var(--color-primary-black);
        transition: var(--transition-primary);
        z-index: 2;
        font-weight: 500;


        &:not(.active):hover {
          color: var(--primary-color);
        }

        &.active {
          position: relative;
          display: inline-block;
          font-weight: 600;
          color: var(--color-black);

          &:after {
            content: '';
            position: absolute;
            left: 0;
            transform: translateX(-45%);
            height: 22px;
            width: 22px;
            border-radius: 50%;
            background-color: var(--primary-color);
            z-index: -1;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .navigation ul {
      gap: 30px;
    }
  }

  @media (max-width: 992px) {
    .navigation {
      padding-left: 10px;

      ul {
        gap: 14px;
        font-size: 15px;
      }
    }
  }
</style>
