<template>
  <div class="separator">
    <span>
      <slot />
    </span>
  </div>
</template>

<style scoped lang="scss">
  .separator {
    position: relative;
    text-align: center;
    width: 100%;
    height: 1px;
    background-color: var(--color-medium-gray);

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 14px;
      font-size: 14px;
      padding: 0 8px;
      background-color: var(--color-white);
    }
  }
</style>
